.add-dialog-review-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.add-dialog-review-main-div {
    margin-top: -10px;
    padding: 0 25px;
    height: 400px;
    display: flex;
    justify-content: center;
}

.add-dialog-review-info {
    width: 70%;
    height: fit-content;
    max-height: 98%;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.5);
    border-radius: 10px;
    position: relative;
    padding-top: 30px;
}

.add-dialog-review-images {
    flex: 1 1;
    height: 98%;
    overflow-y: auto;
    box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.5);
    border-radius: 10px;
    padding: 10px;
    max-width: 310px;
}

.review-label-div {
    display: flex;
    flex-direction: column;
    margin: 20px 20px;
}

.review-label-title {
    font-size: 20px;
    color: var(--dark-beige);
    letter-spacing: 2px;
}

.review-label-value {
    font-size: 15px;
    color: var(--dark-blue);
    letter-spacing: 1px;
    margin-left: 15px;
    white-space: pre-wrap;
}

.review-images-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

}

.review-images-title {
    color: var(--dark-blue);
}

.review-image-info {
    width: 45%;
    height: 50px;
    color: white;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    margin: 5px;
    overflow: hidden;
    user-select: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.review-image-info > p {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.review-image-info:hover > p {
    opacity: 1;
    background-color: rgba(0,0,0,0.5);
}

.review-images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}