.login-main-container {
    height: 100vh;
    min-height: 600px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    position: relative;
}

.login-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
}

.login-logo {
    width: 500px;
    height: 380px;
    height: 40%;
    animation: loginLogoRise 1.5s cubic-bezier(0.46, 0.03, 0.52, 0.96)
}

.login-forgot-pass-p {
    color: var(--dark-beige);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
}

.login-forgot-pass-p:hover,
.login-forgot-pass-p:focus-visible {
    text-decoration: underline;
    outline: none;
}

@keyframes loginLogoRise {
    0% {
        height: 0
    }

    100% {
        height: 40%
    }
}

.login-right-side-div,
.login-left-side-div {
    position: absolute;
    height: 100%;
    width: 20%;
    background-color: var(--dark-blue);
}

.login-left-side-div {
    left: 0
}

.login-right-side-div {
    right: 0;
}