.newsletter-main-div {
    width: 100%;
    height: 100vh;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.newsletter-table {
    height: fit-content;
}