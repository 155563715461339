.icon-picker-main-div {
    background-color: var(--dark-blue);
    height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.icon-picker-search {
    margin-bottom: 10px;
}

.icon-picker-container {
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.icon-button {
    cursor: pointer;
    margin: 10px;
    width: 50px;
    height: 50px;
    transition: all 0.2s ease-in-out;
}

.icon-button:hover {
    transform: scale(1.1);
}


.icon-picker-container::-webkit-scrollbar {
    width: 10px;
}

.icon-picker-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.icon-picker-container::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.icon-picker-container::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}