.project-card-main-div {
    width: 300px;
    height: 400px;
    box-shadow: 2px 2px 7px 2px rgb(0 0 0 / 50%);
    margin: 20px 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    user-select: none;
    animation: cardRise 0.2s ease-in;
    transition: all 0.2s ease-out;
}

.project-card-main-div:hover {
    transform: scale(1.1);
}

.project-card-info-div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 10px 10px;
}

.project-card-actions-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.project-card-actions-inner {
    display: flex;
    width: 100%;
    margin-top: 5px;
    justify-content: space-between;
}

.project-card-title {
    text-align: center;
    font-size: 20px;
    color: var(--dark-beige);
    letter-spacing: 2px;
}

.project-card-chip-div {
    width: fit-content;
    position: absolute;
    top: 155px;
    right: 120px;
    background-color: white;
    border-radius: 15px;
}

.project-card-left-info {
    position: absolute;
    left: 0px;
    top: 0px;
}

.left-info-tasks {
    display: flex;
    width: fit-content;
    align-items: center;
    color: white;
    margin: 10px;
}

.project-card-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-card-progress > p {
    font-size: 16px;
    letter-spacing: 2px;
}

.project-progress-bar {
    width: 85%;
    height: 20px;
    box-shadow: inset 1px 1px 4px 0px #00000087;
    margin: 5px 0;
    border-radius: 10px;
    overflow: hidden;
}

.project-progress-bar-inner {
   height: 100%;
   background-color: var(--medium-beige);
}

.project-progress-bar-text {
    color: var(--dark-beige);
    font-size: 14px;
}

.project-card-slider {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 169px;
    justify-content: space-around;
    position: absolute;
    top: 0;
    right: -30px;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.project-card-main-div:hover .project-card-slider {
    right: 0;
    background-color: #34495ec7;
}

.project-card-image-div {
    max-height: 170px;
    overflow: hidden;
}

@keyframes cardRise {
    0% { opacity: 0;}
    100% { opacity: 1;}
}