.language-changer-main-div {
    width: 85px;
    display: flex;
}

.flag.inactive {
    filter: opacity(0.4);
    cursor: pointer;
}

.flag {
    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.5);
}