.add-dialog-images-upload-main-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.images-upload-divs {
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    justify-content: space-around;
    margin-top: -10px;
}

.main-image-upload-div,
.space-image-upload-div,
.project-image-upload-div {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-upload-section-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-upload-background-image-preview {
    width: 90%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 6px 1px rgba(0,0,0,50%);
}

.image-info-div {
    background-color: rgb(0, 0, 0 , 50%);
    width: 100%;
    font-size: 14px;
    color: white;
    text-align: center;
}

.image-action-div {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 20;
}

.main-image-inner-div {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.multiple-images-inner-div {
    flex: 1 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
    overflow-y: auto;
}

.multiple-images-container {
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.project-upload-multiple-image-preview {
    width: 45%;
    min-width: 150px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    box-shadow: 2px 2px 6px 1px rgba(0,0,0,50%); 
}

.dropzone-preview-container {
    width: 100% !important;
    margin: 0 !important;
    justify-content: center;
}

.dropzone-preview-item {
    padding: 0 !important;
    max-width: 50% !important;
    flex-basis: 50% !important;
    
}