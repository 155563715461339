.ranking-card-main-div {
    /* box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.5); */
    height: 100%;
    width: 30%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
}

.ranking-element {
    height: 55%;
    padding: 5px;
    margin-bottom: 10px;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.5) 30%, transparent 100%);
    border-radius: 10px;
}

.ranking-values {
    flex: 1 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: linear-gradient(0deg, white 65%, transparent 100%);
}

.ranking-bubble {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 3px solid var(--dark-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--dark-blue);
    font-size: 20px;
    filter: drop-shadow(1px 1px 3px white);
}

.ranking-outer-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ranking-outer-bubble-text {
    font-size: 13px;
    color: var(--dark-beige);
}