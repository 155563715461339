.projects-main-div {
    width: 100%;
    height: 100vh;
    padding-top: 75px;
}

.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    padding: 20px;
}

