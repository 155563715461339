.project-info-main-div {
    height: fit-content;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.project-info-dialog-title {
    height: 50px;
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-info-dialog-text-title {
    font-size: 16px;
    color: var(--dark-beige);
    letter-spacing: 2px;
}

.project-info-dialog-text-subtitle {
    font-size: 15px;
    letter-spacing: 1px;
    color: var(--dark-beige);
}

.project-info-form {
    flex: 1 1;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    animation: fieldsRise 0.5s ease-in-out;
}

.project-info-left-div {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.project-info-right-div {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.info-form-checkbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

@keyframes fieldsRise {
    0% {
        margin-bottom: -40px
    }

    100% {
        margin-bottom: 20px
    }
}