.task-info-left-div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.task-info-right-div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.task-icon-picker {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.task-icon-picker > p {
    font-size: 14px;
    color: var(--dark-beige);
}

.task-icon {
    height: 100px;
    width: 100px;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    line-height: 100px;
    color: white;
    background-color: var(--dark-blue);
    border-radius: 10px;
    padding: 5px;
}

.add-more-button {
    height: 40px;
    width: 20px;
    margin-left: 5px;
    background-color: transparent;
    border: 1px solid var(--dark-blue);
    border-radius: 3px;
    cursor: pointer;
    color: var(--dark-blue);
    transition: all 0.2s ease-in-out;
}

.add-more-button:hover {
    background-color: var(--dark-blue);
    color: white;
    font-size: 20px;
}

.autocomplete-plus-add-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}