.top-bar-main-div {
    width: calc(100vw - 75px);
    right: 0;
    height: 75px;
    background: linear-gradient(270deg, black, var(--dark-blue));
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 25px;
    justify-content: space-between;
    z-index: 500;
}


.top-panel-user-display {
    min-height: 40px;
    min-width: 158px;
    width: fit-content;
    justify-content: space-between;
    color: white;
    box-shadow: 0px 0px 0px 2px white;
    display: flex;
    align-items: center;
    padding-right: 15px;
    border-radius: 25px;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.top-panel-user-display:hover {
    box-shadow: 0px 0px 5px 5px white;
}

.inactive-timer-div {
    /* border: 1px dashed white; */
    padding: 5px;
    text-align: center;
    color: white;
}

.inactive-timer {
    font-size: 20px;
    margin-top: 5px;
    font-weight: 600;
    animation: timer 1s infinite;
}

@keyframes timer {
    0% {
        transform: scale(1.0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1.0);
    }
}