.forgot-pass-title {
    color: var(--dark-beige);
    letter-spacing: 2px;
    
}

.forgot-pass-label-div {
    height: 100px;
    margin-bottom: 20px;
}

.forgot-pass-main-div {
    display: flex;
    flex-direction: column;
}