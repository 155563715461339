.views-chart-outer-div {
    height: 100%;
    width: 100%;
    max-width: 950px;
    padding: 20px;
}

.views-chart-main-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 5px 15px 30px;
}

.views-chart-toggle-btn {
    letter-spacing: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 3px 5px rgba(0, 0, 0, 0.13);
    padding: 0px 5px;

}

.views-chart-title {
    font-size: 22px;
    text-align: left;
    margin: 0 15px 0 30px;
    max-width: fit-content;
    overflow: hidden;
}

.chart-title-div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 30px;
}