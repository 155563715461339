.view-more-news-main-div {
    display: flex;

}

.view-more-news-info-div {
    flex: 1 1;
    height: 500px;
    border-top: 5px solid var(--dark-blue);
}

.view-more-news-data-div {
    width: 25%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}

.view-more-news-data {
    flex: 1 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: 5px solid var(--dark-blue);
}

.view-more-news-meta {
    height: 25%;
    margin-top: 20px;
    border-top: 5px solid var(--dark-blue);
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    line-height: 20px;
    position: relative;
}

.news-info-title {
    width: 100%;
    height: 150px;
    padding: 15px 25px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 26px;
    color: white;
    text-shadow: 1px 1px 4px black;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
}

.news-info-title > p {
    width: 80%;
}

.news-info-title-background {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    right: 0;
    background: linear-gradient(to top, rgba(255,255,255,1) 10%, transparent 100%);
}

.news-info-text {
    height: 315px;
    text-align: justify;
    width: 100%;
    overflow-y: auto;
    padding: 30px 40px;
    white-space: pre-line;
    color: var(--dark-beige);
}

.news-info-text::-webkit-scrollbar {
    width: 10px;
}

.news-info-text::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.news-info-text::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.news-info-text::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}