.boolean-main-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.progress-main-div {
    position: relative;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progress-inner-div {
    width: 90%;
    height: 100%;
    border-radius: 20px;
    background-color: #e9e9e9;
    overflow: hidden;
    box-shadow: inset 1px 1px 5px 0px rgb(0, 0, 0, 50%);
}

.progress-inner-inner-div {
    background-color: #65c466;
    height: 100%;
}

.actions-main-div {
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.actions-main-div > svg:hover {
    transform: scale(1.1);
    cursor: pointer;
}