.standard-button {
    min-width: 200px;
    width: fit-content;
    height: 35px;
    cursor: pointer;
    position: relative;
    border: none;
    outline: none;
    background: none;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    user-select: none;
}

.standard-button.loading {
    min-width: 0;
    width: fit-content;
    height: 75px;
    position: relative;
    border: none;
    outline: none;
    background: none;
    user-select: none;
    cursor: default;
    pointer-events: none;
    transition: all 0.5s ease-in-out;
}

.standard-button:disabled {
    opacity: 0.2;
    cursor: default;
}

.standard-button-top-border {
    position: absolute;
    top: -2px;
    right: 0;
    opacity: 1;
    transform: rotateX(180deg);
    transition: all 0.2s ease-in-out;
}

.standard-button-bottom-border {
    position: absolute;
    bottom: -2px;
    right: 0;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.standard-button:not([disabled]):hover > .standard-button-bottom-border {
    transform: translateY(4px)
}

.standard-button:not([disabled]):hover > .standard-button-top-border {
    transform: translateY(-4px) rotateX(180deg)
}

.standard-button:not([disabled]):focus-visible > .standard-button-bottom-border {
    transform: translateY(4px)
}

.standard-button:not([disabled]):focus-visible > .standard-button-top-border {
    transform: translateY(-4px) rotateX(180deg)
}

.loading-text {
    position: absolute;
    width: 100%;
    bottom: -20px;
    text-align: center;
}
