.page-top-main-div {
    width: 100%;
    background: linear-gradient(270deg, black, var(--dark-blue));
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
    padding: 20px 10px 10px;
    user-select: none;
    box-shadow: inset 0px 12px 20px 0px rgb(0 0 0 / 50%);
}

.page-top-top-div {
    width: 100%;
    height: 33%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
}

.page-top-title {
    color: white;
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 4px;
}

.page-top-bottom-div {
    width: 100%;
    display: flex;
    padding: 20px 40px 0px;
    margin: 20px 0 20px 0;
    justify-content: space-between;
    align-items: center;
}


.page-top-bottom-view-changer {
    display: flex;
    align-items: center;
}

.page-top-bottom-view-changer>p {
    margin-right: 10px;
    color: white;
    text-transform: uppercase;
}

.page-top-entity-info {
    display: flex;
}

.top-page-filter-panel {
    position: relative;
    width: 100%;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(270deg, black, var(--dark-blue));
}

.top-page-filter-panel.hidden {
    position: relative;
    width: 100%;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background: linear-gradient(270deg, black, var(--dark-blue));
}

.top-page-filter-panel-handle {
    position: absolute;
    bottom: -20px;
    width: 80px;
    height: 25px;
    background-color: black;
    pointer-events: all;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 50;
}

.top-page-filter-content {
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 1;
    align-items: center;
    justify-content: center;
}

.top-page-filter-content.hidden {
    pointer-events: none;
    opacity: 0;
}

.top-page-filter-title {
    position: absolute;
    top: -5px;
    color: white;
    font-size: 26px;
    letter-spacing: 2px;
}