.edit-profile-main-div {
    width: 80%;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.edit-profile-password {
    display: flex;
    flex-direction: column;
    align-items: center;
}