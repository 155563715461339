.users-main-div {
    width: 100%;
    height: 100vh;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    /* flex: 1 1; */
    overflow-y: auto;
}

.users-container::-webkit-scrollbar {
    width: 10px;
}

.users-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.users-container::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.users-container::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}