.filter-main-div {
    width: 100%;
    height: 85%;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.filter-label-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
}

.filter-label-div > p{
    color: white;
    letter-spacing: 2px;
    margin-bottom: 5px;
}