.view-more-contributions-main-div {
    height: fit-content;
    min-height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.view-more-contributions-title {
    font-size: 20px;
    color: var(--dark-blue);
    text-align: center;
}

.view-more-contributions-steps-div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1 1
}

.view-more-contributions-steps {
    height: 100px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.view-more-contributions-steps-outer > p {
    font-size: 15px;   
    margin-bottom: 10px;
    max-width: fit-content;
    text-align: center;
    height: 30px;
}

.view-more-contributions-steps-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}