.projects-stats-panel-main-div {
    width: 100%;
    height: fit-content;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 1px 7px 0px rgb(0 0 0 / 50%);
    border-radius: 10px;
}

.projects-stats-panel-content {
    width: 100%;
    flex: 1 1;
    display: flex; 
    align-items: center;
}

.projects-stats-panel-title {
    width: 100%;
    height: 50px;
    background: linear-gradient(270deg, black, var(--dark-blue));
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects-stats-panel-title > p {
    font-size: 22px;
    color: white;
    letter-spacing: 2px;
}

.projects-charts-div {
    height: 100%;
    width: 60%;
    padding: 10px;
}

.projects-stats-table {
    height: 100%;
    width: 40%;
    padding: 10px;
}



/* ----- contributions-stats-panel --------------------- */

.contribution-timeline {
    height: 500px;
    width: 30%;
    padding: 10px;
    overflow-y: auto;
}

.contribution-timeline::-webkit-scrollbar {
    width: 10px;
}

.contribution-timeline::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.contribution-timeline::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.contribution-timeline::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}