.reports-main-div {
    width: 100%;
    height: 100vh;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-width: 1000px;
}

.reports-top-div {
    min-height: 125px;
    height: 125px;
    width: 100%;
    background: linear-gradient(270deg, black, var(--dark-blue));
    box-shadow: inset 0px 12px 20px 0px rgb(0 0 0 / 50%);
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 30px 5% 0;
}

.reports-content-div {
    width: 100%;
    /* overflow-y: auto; */
}

.reports-content-div::-webkit-scrollbar {
    width: 10px;
}

.reports-content-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.reports-content-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.reports-content-div::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}

.reports-google-analytics,
.reports-projects-charts,
.reports-news-charts,
.reports-contributions-charts,
.reports-tasks-charts {
    width: 100%;
    min-height: 400px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 10px;
}

.reports-google-analytics {
    border: 2px dashed blue;
}