.icons-display-main-div {
    height: 500px;
    width: 50%;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icons-display-search-div {
    height: 75px;
    background-color: var(--dark-blue);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    width: 100%;
}


.icons-display-search-div > p {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    color: white;
    margin-right: 25px;
}

.icons-display-icons-div {
    width: 100%;
    flex: 1 1;
    overflow-y: auto;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    gap: 10px;
}

.icons-div {
    width: 100px;
    cursor: pointer;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in-out;
    text-align: center;
    padding: 5px;
    border-radius: 10px;
}

.icons-div:hover {
    transform: scale(1.1);
    background-color: var(--dark-blue);
    color: white;
}

.icons-div > p {
    margin-top: 5px;
    font-size: 14px;
}

.icons-display-icons-div::-webkit-scrollbar {
    width: 10px;
}

.icons-display-icons-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.icons-display-icons-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.icons-display-icons-div::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}