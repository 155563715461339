.partner-view-more-main-div {
    height: 300px;
    display: flex;
}

.partner-view-more-left-div {
    width: 60%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.partner-view-more-right-div {
    flex: 1 1;
}

.partner-view-more-info {
    height: 70%;
    margin-bottom: 10px;
    width: 100%;
    border-top: 5px solid var(--dark-blue);
    border-radius: 10px;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.partner-view-more-metadata {
    flex: 1 1;
    border-top: 5px solid var(--dark-blue);
    border-radius: 10px;
    position: relative;
    padding: 5px;
}

.partner-view-more-paper-title {
    position: absolute;
    font-size: 14px;
    color: var(--dark-beige);
    right: 5px;
    top: 2px;
}

.partner-view-more-contributions {
    height: 100%;
    border-top: 5px solid var(--dark-blue);
    border-radius: 10px;
    position: relative;
    padding: 20px 20px 10px;
    overflow-y: auto;
}

.partner-view-more-metadata-p > p {
    font-size: 14px;
    color: var(--dark-beige);
}

.partner-view-more-image {
    height: 80px;
}

.partner-view-more-info-info {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
}

.partner-view-more-info-info > p {
    margin: 2px 0;
}