.tasks-stats-panel-main-div {
    width: 100%;
    height: fit-content;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 1px 7px 0px rgb(0 0 0 / 50%);
    border-radius: 10px;
}

.tasks-stats-panel-content {
    width: 100%;
    flex: 1 1;
    display: flex;
    align-items: center;
}

.tasks-stats-panel-title {
    width: 100%;
    height: 50px;
    background: linear-gradient(270deg, black, var(--dark-blue));
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tasks-stats-panel-title>p {
    font-size: 22px;
    color: white;
    letter-spacing: 2px;
}

.tasks-stats-panel-left {
    height: 500px;
    width: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.tasks-charts-div {
    width: 45%;
}

.tasks-stats-panel-left-charts {
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 90%;
}

.tasks-stats-panel-left-title {
    height: 10%;
    display: flex;
    align-items: center;
}

.tasks-stats-panel-left-title > p {
    font-size: 22px;
    text-align: left;
    margin: 0 30px;
}

.tasks-stats-table {
    height: 100%;
    width: 30%;
    padding: 10px;
}