.contribution-card-main-div {
    margin: 10px 0;
    padding: 15px;
    box-shadow: inset 1px 1px 6px 1px rgb(0 0 0 / 50%);
    border-radius: 10px;
    cursor: pointer;
    animation: contributionsRise 0.5s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.contribution-card-main-div:hover {
    transform: scale(1.05);
}

.contribution-card-date {
    font-size: 15px;
    font-weight: 600;
    color: var(--dark-blue);
}

.contribution-card-info {
    font-size: 14px;
    margin-left: 10px;
}

@keyframes contributionsRise {
    0% { transform: translateY(300px) }
    100% { transform: translateY(0);} 
}