.delete-dialog-main-div {
    display: flex;
    flex-direction: column;
}

.delete-dialog-text-div {
    letter-spacing: 1px;
    line-height: 20px;
    margin-bottom: 10px;
    font-size: 15px;
}

.delete-dialog-text-div > p {
    white-space: pre-line;
    margin-bottom: 15px;
    color: var(--dark-beige);
}

.delete-dialog-actions-div {
    display: flex;
    justify-content: space-around;
}

.delete-dialog-actions-div > * {
    margin-right: 5px;
}