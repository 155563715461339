.dashboard-main-div {
    width: 100%;
    height: 100vh;
    padding-top: 75px;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-top-div {
    width: 100%;
    background: linear-gradient(270deg, black, var(--dark-blue));
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding-top: 25px;
    /* padding-bottom: 60px; */
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
}

.dashboard-bottom-div {
    height: calc(100% - 200px);
    overflow: hidden;
    display: flex;
    min-height: 500px;
    width: 100%;
}

.dashboard-contributions-timeline {
    width: 33%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    margin-top: 10px;
    animation: contributionsRollUp 1s ease-in-out;
}

.dashboard-rankings-div {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 40px;
    padding-right: 20px;
}

.dashboard-ranking-project,
.dashboard-ranking-news,
.dashboard-ranking-partner {
    height: 30%;
    width: calc(100%-20px);
    margin: 0 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    animation: rankingSlide cubic-bezier(0.68, -0.55, 0.27, 1.55) ;
}

.dashboard-ranking-project {
    animation-duration: 1s;
}

.dashboard-ranking-news {
    animation-duration: 1.5s;
}

.dashboard-ranking-partner {
    animation-duration: 2s;
}

.ranking-title {
    font-size: 18px;
    color: var(--dark-blue);
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.dashboard-inner-ranking {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1 1;
}

.dashboard-contributions-timeline::-webkit-scrollbar {
    width: 10px;
}

.dashboard-contributions-timeline::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.dashboard-contributions-timeline::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.dashboard-contributions-timeline::-webkit-scrollbar-thumb:hover {
    background-color: var(--light-beige);
}

.project-ranking-display {
    height: 100%;
    width: 100%;
    color: white;
    font-size: 20px;
    text-shadow: 1px 1px black;
    margin: 5px;
}

@keyframes contributionsRollUp {
    0% {
        margin-top: 700px
    }

    100% {
        margin-top: 10px
    }
}

@keyframes rankingSlide {
    0% {
        transform: translateX(1300px)
    }

    100% {
        transform: translateX(0px);
    }
}