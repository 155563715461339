.project-view-more-container {
    height: 550px;
    display: flex;
}

.project-view-more-left-div {
    height: 100%;
    width: 30%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
}

.project-view-more-left-tasks {
    flex: 1 1;
    overflow-y: scroll;
    padding: 5px;
    border-top: 5px solid var(--dark-blue);
}

.project-view-more-left-metadata {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    border-top: 5px solid var(--dark-blue);
}

.project-view-more-right-div {
    height: 100%;
    flex: 1 1;
    display: flex;
}

.project-view-more-right-info {
    height: 100%;
    flex: 1 1;
    margin: 0 15px;
    border-radius: 10px;
    padding-bottom: 10px;
    border-top: 5px solid var(--dark-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-view-more-right-images {
    height: 100%;
    width: 200px;
    border-top: 5px solid var(--dark-blue);
    overflow-y: auto;
    text-align: center;
}

.project-view-more-right-images > p {
    margin-top: 10px;
    font-size: 14px;
    color: var(--dark-beige);
    letter-spacing: 2px;
    margin-bottom: -25px;
    transition: all 0.4s ease-in-out;
}

.project-view-more-right-images:hover > p {
    margin-bottom: 5px;
}

.project-info-title {
    width: 100%;
    height: 150px;
    padding: 15px 25px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 26px;
    color: white;
    text-shadow: 1px 1px 4px black;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
}

.project-info-title-background {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    right: 0;
    background: linear-gradient(to top, rgba(255,255,255,1) 10%, transparent 100%);
}

.project-info-text {
    margin: 10px;
    flex: 1 1;
    width: 100%;
    overflow-y: auto;
}

.project-view-more-image {
    cursor: pointer;
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.project-view-more-image:hover {
   opacity: 0.7; 
}

.project-info-text::-webkit-scrollbar {
    width: 10px;
}

.project-info-text::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.project-info-text::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--medium-beige);
    background-clip: content-box;
}

.project-info-text::-webkit-scrollbar-thumb:hover{
    background-color: var(--light-beige);
}

.text-link {
    color: var(--dark-beige);
    cursor: pointer;
}

.text-link:hover {
    color: purple;
    cursor: pointer;
}

.project-view-more-chip {
    position: absolute;
    left: 20px;
    top: 100px;
}