.news-card-main-div {
    width: 400px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 20px;
    box-shadow: 3px 5px 4px 0px rgb(0 0 0 / 50%);
    border: 1px solid white;
    animation: cardRise 0.4s ease-in;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    user-select: none;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.news-card-black-background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}

.news-card-top-div {
    width: 100%;
    height: fit-content;
    z-index: 10;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
}

.news-card-bottom-div {
    width: 100%;
    height: 120px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    transform: translateY(35px);
    transition: all 0.2s ease-in-out;
}

.news-card-title-div {
    flex: 1 1;
    padding: 0px 20px;
}

.news-card-title-div > p{
    color: white;
    font-size: 23px;
    letter-spacing: 2px;
    font-weight: 300;
    font-family: 'Raleway', sans-serif;
}

.news-card-action-div {
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 50px;
}

.news-card-main-div:hover .news-card-bottom-div,
.news-card-main-div:focus-visible .news-card-bottom-div {
    transform: translateY(0);
}

.news-card-main-div:hover, 
.news-card-main-div:focus-visible {
    transform: scale(1.1);
}

.news-card-date {
    color: white;
    text-align: center;
    width: 100px;
    font-size: 14px;
}

.news-card-stats {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    text-align: center;
    font-size: 14px;
}

.stats-div {
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
}

.news-card-online-div {
    position: absolute;
    width: 120px;
    height: 30px;
    border: 1px solid white;
    border-radius: 10px;
    top: -10px;
    left: 35%;
    z-index: 20;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2px;
    cursor: pointer;
    box-shadow: inset 1px 1px 4px 1px black;
    background-color: white;
}