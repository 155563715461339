.profile-main-div {
    height: 430px;
    display: flex;
    justify-content: space-around;
}

.profile-portrait-div.large,
.profile-portrait-div.small {
    border-top: 5px solid var(--dark-blue);
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.5);
    transition: all 0.4s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-portrait-div.large {
    width: 100%;
}

.profile-portrait-div.small {
    width: 45%;
}

.profile-info-div.show {
    width: 45%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    border-top: 5px solid var(--dark-blue);
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.5);
    transition: all 0.3s ease-in-out;
}

.profile-info-div.hide {
    position: absolute;
    width: 0;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.profile-picture-div {
    height: 150px;
    width: 150px;
    border: 5px solid white;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}

.profile-userType-wrapper-div {
    position: absolute;
    width: 100%;
    top: 130px;
    display: flex;
    justify-content: center;
    z-index: 20;
    overflow: hidden;
}

.profile-bottom-div {
    width: 100%;
    margin-top: 15px;
    padding: 10px 0;
    box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 50%);
    border: 1px solid var(--dark-beige);
    height: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--dark-blue);
    color: white;
}

.profile-actions {
    flex: 1 1;
    display: flex;
    align-items: flex-end;
    padding-bottom: 25px;
    width: 100%;
    justify-content: space-around;
}

.profile-edit-div {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.profile-portrait-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.5);
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-in-out;
}


.profile-picture-div:hover .profile-portrait-actions {
    opacity: 1;
    z-index: 10;
}