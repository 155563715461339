.pass-reset-main-div {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    position: relative;
}

.pass-reset-logo {
    width: 500px;
    height: 200px;
    margin-top: -100px;
    animation: logoRise 1.5s ease-in-out;
}

.pass-reset-title {
    margin-top: 50px;
    font-size: 20px;
    color: var(--dark-beige);
    letter-spacing: 2px;
}

.pass-reset-form {
    width: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
}

@keyframes logoRise {
    0% {
        height: 0px;
    }
    100% {
        height: 200px;
    }
}