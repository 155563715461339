.partners-small-card-main-div {
    max-width: 600px;
    height: 75px;
    display: flex;
    align-items: center;
    margin: 15px 10px;
    box-shadow: 1px 1px 2px 0px black;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.partners-small-card-main-div:hover {
    transform: scale(1.05);
}

.partners-small-card-image {
    height: 100%;
    width: 23%;
    max-width: 150px;
    margin-right: 25px;
}

.partners-small-card-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around
}

.partners-small-card-title {
    font-size: 20px;
    letter-spacing: 2px;
    color: var(--dark-blue);
}

.partners-small-card-site {
    font-size: 14px;
    color: var(--medium-beige);
    text-decoration: underline;
    cursor: pointer;
}

.partners-small-card-address {
    font-size: 14px;
    color: var(--dark-blue);
}

/* ----------------------------------- */

.partners-card-main-div {
    width: 400px;
    height: 200px;
    margin: 10px;
    background-color: #2e2e2e;
    border-radius: 10px;
    box-shadow: 1px 1px 6px 0px black;
    display: flex;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    position: relative;
    animation: partnerCard 0.5s ease-in-out;
}

.partners-card-left-div {
    width: 40%;
    border: 6px solid var(--dark-blue);
    background-color: white;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    transform: scale(1.1);
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    box-shadow: inset -5px 0px 8px -5px black;
}

.partners-card-main-div:hover .partners-card-action-div {
    opacity: 1;
    z-index: 10;
}

.partners-card-action-div {
    position: absolute;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    height: 100%;
    width: 38%;
    left: 0;
    opacity: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    justify-content: center;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.partners-card-right-div {
    flex: 1 1;
    padding: 20px 0 20px 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.partners-card-title {
    border-bottom: 2px solid var(--dark-blue);
}

.partners-card-title > p {
    font-weight: 600;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.partners-info-icon {
    margin: 5px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.partners-card-main-div:hover {
    transform: scale(1.03);
}

@keyframes partnerCard {
    0% { transform: scale(0.7);}
    100% { transform: scale(1);}
}

.partners-card-contributions {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    z-index: 20;
    color: white;
    font-size: 14px;
}