.task-small-card-container {
    width: 100%;
    max-width: 400px;
    height: 100px;
    margin: 15px 0;
    display: flex;
    padding: 0 10px;
    align-items: center;
    box-shadow: inset 1px 1px 6px 0px var(--dark-beige);
    border-radius: 10px;
    cursor: pointer;
    animation: taskCardRise 0.5s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.task-small-card-container:hover {
    transform: scale(0.98);
}

.task-small-card-icon-div {
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-small-card-right {
    height: 100%;
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.task-small-card-upper {
    width: 100%;
    height: 60%;
    font-size: 15px;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.task-small-card-image {
    width: 55px;
    height: 55px;
    margin-right: 10px;
}

.task-small-card-bottom {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task-small-card-progress {
    height: 100%;
    width: 65%;
    box-shadow: inset 1px 1px 7px 0px rgba(0,0,0,0.5);
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--light-beige);
}

.task-small-card-progress-inner {
    height: 100%;
    background-color: var(--dark-beige)
}

@keyframes taskCardRise {
    0% { transform: translateY(400px); }
    100% { transform: translateY(0px);}
}


.task-card-container {
    width: 100%;
    max-width: 400px;
    height: 125px;
    margin: 15px 0;
    display: flex;
    padding-left: 10px;
    margin: 15px 5px;
    align-items: center;
    box-shadow: 1px 1px 6px 0px var(--dark-beige);
    animation: taskCardRise 0.5s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.task-card-container:hover {
    transform: scale(1.02);
}

.task-card-icon-div {
    height: 100%;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-card-right {
    height: 100%;
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.task-card-upper {
    width: 100%;
    height: 70%;
    font-size: 15px;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.task-card-image {
    width: 55px;
    height: 55px;
    margin-right: 10px;
}

.task-card-bottom {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.task-card-progress {
    height: 100%;
    width: 65%;
    box-shadow: inset 1px 1px 7px 0px rgba(0,0,0,0.5);
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--light-beige);
}

.task-card-progress-inner {
    height: 100%;
    background-color: var(--dark-beige)
}

.task-card-actions-div {
    width: 0px;
    height: 100%;
    box-shadow: -3px 0px 9px -6px black;;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.task-card-container:hover .task-card-actions-div {
    width: 50px;
    opacity: 1;
}

.task-card-container:focus-visible .task-card-actions-div {
    width: 50px;
    opacity: 1;
}

.task-card-actions-div > svg {
    cursor: pointer;
}

.task-card-chip-div {
    width: fit-content;
    position: absolute;
    top: -10px;
    left: 0px;
    background-color: white;
}
