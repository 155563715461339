.add-news-review-info {
    width: 100%;
    height: fit-content;
    max-height: 74%;
    min-height: 80px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.5);
    border-radius: 10px;
    position: relative
}

.add-news-info-div {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin-right: 10px
}