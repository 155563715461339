.text-area-main-div {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.text-area-buttons-div {
    margin-top: 10px;
    height: 30px;
    display: flex;
    flex-direction: column;
    margin-left: -2px;
}

.text-area-action-button {
    outline: none;
    width: 30px;
    border: 1px solid var(--dark-beige);
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px ;
    background-color: transparent;
    cursor: pointer;
}

.text-area-action-button:hover {
    background-color: var(--very-light-beige);
}

.text-area-add-submit-btn {
    width: 100%;
    outline: none;
    background-color: transparent;
    border: 1px solid var(--dark-blue);
    padding: 5px;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.text-area-add-submit-btn:hover {
    transform: scale(1.02);
}

.text-area-add-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
}