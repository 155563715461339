.view-more-icons {
    width: 100%;
    height: fit-content;
}

.view-more-icons-info {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: center;
    height: 125px;
    padding: 10px;
}

.view-more-icons-meta {
    font-size: 14px;
    color: var(--dark-beige);
    height: 100px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.view-more-icons-actions {
    display: flex;;
    margin-top: 15px;
    align-items: center;
    justify-content: space-around;
}

.view-more-icon-tasks-alert {
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.view-more-icons-info > img {
    transition: all 0.3s ease-in-out;
}

.view-more-icons-info > img:hover {
    border-radius: 5px;
    scale: 1.1;
    background-color: var(--dark-blue);
}