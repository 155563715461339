.add-dialog-content {
    padding: 20px 24px !important;
}

.add-dialog-inputs {
    margin: 25px 0;
    height: 450px;
    overflow: auto;
}

.add-dialog-action {
    display: flex;
    justify-content: flex-end;
}

.stepper-buttons {
    width: fit-content;
    min-width: 100px;
    height: 25px;
    border: 2px solid var(--dark-beige);
    outline: none;
    border-radius: 5px;
    background-color: transparent;
    margin: 0 10px;
    color: var(--dark-beige);
    transition: all 0.2s ease-in-out;
}

.stepper-buttons:disabled {
    border: 0;
}

.stepper-buttons:not([disabled]):hover {
    border-radius: 20px;
    cursor: pointer;
}