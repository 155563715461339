.task-view-more-main-div {
    height: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.task-view-more-contributions {
    height: 400px;
    width: 35%;
    border-top: 5px solid var(--dark-blue);
    position: relative;
    overflow-y: auto;
}

.task-view-more-partners {
    height: 400px;
    padding: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15%;
    border-top: 5px solid var(--dark-blue);
    position: relative;
    overflow-y: auto;
}

.task-view-more-info-div {
    height: 400px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
}

.task-view-more-info {
    height: 75%;
    border-top: 5px solid var(--dark-blue);
    position: relative;
    margin-bottom: 10px;
}

.task-view-more-meta {
    border-top: 5px solid var(--dark-blue);
    position: relative; 
    flex: 1 1;
    padding: 10px 20px;
}

.task-view-more-div-title {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 14px;
    color: var(--dark-beige);
}

.task-view-more-meta-inner {
    color: var(--dark-beige);
    font-size: 15px;
}

.task-view-more-partner-logo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
    margin: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.task-view-more-partner-logo:hover {
    border-radius: 10px;
}

.task-view-more-info-header {
    width: 100%;
    height: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.task-view-more-info-title {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.task-view-more-info-title > p {
    font-size: 18px;
    color: var(--dark-beige);
    letter-spacing: 2px;
    max-width: 240px;
}

.task-view-more-info-progress {
    height: 20px;
    width: 90%;
    box-shadow: inset 1px 1px 7px 0px rgb(0 0 0 / 50%);
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--light-beige);
    position: relative;
}

.task-view-more-info-progress > p {
    color: white;
    position: absolute;
    top: 0px;
    left: 45%;
    text-shadow: 1px 1px 2px black;
    font-size: 18px;
    font-weight: 600;
}

.task-view-more-info-inner-progress {
    height: 100%;
    background-color: var(--dark-beige);
    box-shadow: 1px 1px 7px 0px rgb(0 0 0 / 50%);
}

.task-view-more-info-body {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}


