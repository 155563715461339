.dialog-content-div {
    display: flex;
    flex-direction: column;
}

.dialog-top-bar {
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    user-select: none;
}

.dialog-inner-content {
    margin: 20px;
    flex: 1 1;
}

.dialog-top-bar > p {
    color: white;
    letter-spacing: 2px;    
    text-transform: capitalize;
    font-weight: 600;
}

.dialog-close-icon {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.dialog-close-icon:hover {
    transform: scale(1.1);
}

.dialog-outer-div .MuiPaper-root {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}