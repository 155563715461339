.dashboard-card-main-div {
    width: 200px;
    height: 175px;
    margin: 25px 5px 0 5px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.dashboard-card-main-div:hover {
    opacity: 1;
    background: linear-gradient(to top, var(--dark-blue), rgba(0,0,0,0.5));
    box-shadow: 0 0 15px 0px rgba(0,0,0,0.5) ;
    border-radius: 15px;
    transform: scale(1.1);
}

.dashboard-card-info {
    height: 60%;
    width: 100%;
}

.dashboard-card-title {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
}

.dashboard-card-text {
    color: white;
    font-size: 50px;
    text-align: left;
    font-weight: 600;
}